import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { User } from "../types/user";
import { getCurrentUser } from "../utils/firebase/auth";
import { auth } from "../utils/firebase/init";

export const useAuthUser = (): {
  user?: User;
  loading: boolean;
} => {
  const [currentUser, setCurrentUser] = useState<User>();
  const [loggedInUser, fetching] = useAuthState(auth);
  const [loading, setLoading] = useState(true);

  const fetchUser = async (uid: string): Promise<void> => {
    const user = await getCurrentUser(uid);
    setCurrentUser(user);
    setLoading(false);
  };

  useEffect(() => {
    if (fetching) {
      return;
    }

    if (loggedInUser?.uid) {
      setLoading(true);
      fetchUser(loggedInUser.uid);
    } else {
      setLoading(false);
    }
  }, [loggedInUser, fetching]);

  return {
    user: currentUser,
    loading,
  };
};
