import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import {
  addDoc,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore/lite";
import { Language } from "../../types/language";
import { User } from "../../types/user";
import { auth, db } from "./init";

export const signIn = async (
  email: string,
  password: string
): Promise<void> => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
  }
};

export const signUp = async (
  domain: string,
  email: string,
  password: string,
  date: string,
  languages: Language[]
): Promise<void> => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    const usersRef = collection(db, "users");

    addDoc(usersRef, {
      id: user.uid,
      authProvider: "local",
      domain,
      email,
      date,
      languages: languages.map((lng) => lng.toString()),
    });
  } catch (err) {
    console.error(err);
  }
};

export const signOut = async (): Promise<void> => {
  return auth.signOut();
};

export const getCurrentUser = async (
  uid: string
): Promise<User | undefined> => {
  const q = query(collection(db, "users"), where("id", "==", uid));
  const querySnapshot = await getDocs(q);

  const user = querySnapshot?.docs?.[0];

  if (user) {
    return user.data() as User;
  }
};
