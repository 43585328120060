import React from "react";
import { Typography } from 'antd'
import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";

import { PublicLayout } from "./PublicLayout";

const { Title } = Typography

export const LoadingLayout: React.FC = () => {
  return (
    <PublicLayout>
      <Title level={2}>Loading...</Title>
      <Loader  />
    </PublicLayout>
  );
};

const Loader = styled(LoadingOutlined)`
  font-size: 40px;
  color: var(--lilac);
`

Loader.displayName = 'Loader'