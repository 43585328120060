import React from "react";
import { Menu, Layout, Button, Row, Col } from "antd";
import { useLocation, Link } from "react-router-dom";
import styled from "styled-components";
import { ExportOutlined } from "@ant-design/icons";
import {
  HomeOutlined,
  StarOutlined,
  LogoutOutlined,
  MailOutlined,
  TeamOutlined,
} from "@ant-design/icons";

import { signOut } from "../../utils/firebase/auth";
import { useCurrentUser } from "../User";

const { Sider } = Layout;

const getSelectedKeys = (current: string, domain?: string): string[] => {
  switch (current) {
    case `/${domain}/home`:
      return ["1"];
    case `/${domain}/news`:
      return ["2"];
    case `/${domain}/guests`:
      return ["3"];
    case `/${domain}/contact`:
      return ["4"];
    default:
      return [];
  }
};

export const Navigation: React.FC = () => {
  const { pathname } = useLocation();
  const { user } = useCurrentUser();
  const domain = user?.domain;
  const url = `https://${domain}.ourspecialday.info`;

  const selectedKeys = getSelectedKeys(pathname, domain);

  const handleLogout = async (): Promise<void> => {
    await signOut();
    window.location.reload();
  };

  if (!user) {
    return null;
  }

  return (
    <FullHeightStickySider trigger={null} theme="dark">
      <Logo>Wedding Admin</Logo>

      <Row gutter={[16, 16]} style={{ marginBottom: 'var(--padding-m)'}}>
        <Col span={20} offset={2}>
          <a href={url} target="_blank" rel="noreferrer">
            <Button type="primary" block icon={<ExportOutlined />}>
              Wedding page
            </Button>
          </a>
        </Col>
      </Row>

      <Menu mode="inline" theme="dark" selectedKeys={selectedKeys}>
        <Menu.Item key="1" icon={<HomeOutlined />}>
          <Link to={`/${domain}/home`}>Home</Link>
        </Menu.Item>
        <Menu.Item key="2" icon={<StarOutlined />}>
          <Link to={`/${domain}/news`}>News</Link>
        </Menu.Item>
        <Menu.Item key="3" icon={<TeamOutlined />}>
          <Link to={`/${domain}/guests`}>Guests</Link>
        </Menu.Item>
      </Menu>

      <BottomMenu mode="inline" theme="dark" selectedKeys={selectedKeys}>
        <Menu.Item key="4" icon={<MailOutlined />}>
          <Link to={`/${domain}/contact`}>Contact</Link>
        </Menu.Item>
        <Menu.Item key="5" icon={<LogoutOutlined />} onClick={handleLogout}>
          Logout
        </Menu.Item>
      </BottomMenu>
    </FullHeightStickySider>
  );
};

const FullHeightStickySider = styled(Sider)`
  height: 100vh;
  min-height: 320px;
  position: sticky;
  top: 0;

  @media (max-width: 720px) {
    display: none;
  }
`;

FullHeightStickySider.displayName = "FullHeightStickySider";

const BottomMenu = styled(Menu)`
  position: absolute;
  bottom: 0;
  margin-bottom: var(--padding-m);
`;

BottomMenu.displayName = "BottomMenu";

const Logo = styled.div`
  min-height: 64px;
  padding: var(--padding-m) var(--padding-l);
  color: var(--seashell);
`;

Logo.displayName = "Logo";
