import React from "react";
import styled from "styled-components";

export const PublicLayout: React.FC = ({ children }) => {
  return (
    <Wrapper>
      <Center>{children}</Center>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
`;

Wrapper.displayName = "Wrapper";

const Center = styled.div`
  min-width: min(100vw, 400px);
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

Center.displayName = "Center";
