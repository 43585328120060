import React from "react";
import { Button, PageHeader } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  HomeOutlined,
  StarOutlined,
  LogoutOutlined,
  TeamOutlined,
} from "@ant-design/icons";

import { signOut } from "../../utils/firebase/auth";
import { useCurrentUser } from "../User";

export const MobileNavigation: React.FC = () => {
  const { user } = useCurrentUser();
  const domain = user?.domain;

  const handleLogout = async (): Promise<void> => {
    await signOut();
    window.location.reload();
  };

  if (!user) {
    return null;
  }

  return (
    <Wrapper>
      <PageHeader
        style={{ position: "fixed", top: 0, width: "100vw", zIndex: 20 }}
        title="Wedding Admin"
        extra={[
          <Link to={`/${domain}/home`}>
            <Button type="primary" icon={<HomeOutlined />} />
          </Link>,
          <Link to={`/${domain}/news`}>
            <Button type="primary" icon={<StarOutlined />} />
          </Link>,
          <Link to={`/${domain}/guests`}>
            <Button type="primary" icon={<TeamOutlined />} />
          </Link>,
          <Button icon={<LogoutOutlined />} onClick={handleLogout} />,
        ]}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: none;
  background-color: var(--white);

  @media (max-width: 720px) {
    display: block;
  }
`;
