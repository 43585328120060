import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LoadingLayout } from "../components/Layout";

import { MainLayout } from "../components/Layout/MainLayout";
import { UserContextProvider } from "../components/User/UserContext";

const Main = lazy(() => import("."));
const Home = lazy(() => import("./domain/home"));
const Login = lazy(() => import("./domain/login"));
const Register = lazy(() => import("./domain/register"));
const News = lazy(() => import("./domain/news"));
const Guests = lazy(() => import("./domain/guests"));
const Contact = lazy(() => import("./domain/contact"));

export const App: React.FC = () => {
  return (
    <UserContextProvider>
      <BrowserRouter>
        <Suspense fallback={<LoadingLayout />}>
          <Routes>
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />

            <Route path=":domain" element={<MainLayout />}>
              <Route path="home" element={<Home />} />
              <Route path="news" element={<News />} />
              <Route path="guests" element={<Guests />} />
              <Route path="contact" element={<Contact />} />
            </Route>

            <Route path="/" element={<Main />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </UserContextProvider>
  );
};
