import { useState } from "react";
import { Language } from "../types/language";

const DEFAULT_LANGUAGE = "pl";

export const useCurrentLanguage = (): {
  language: Language;
  setLanguage: (value: Language) => void;
} => {
  const [language, setLanguage] = useState<Language>(DEFAULT_LANGUAGE);

  return {
    language,
    setLanguage,
  };
};
