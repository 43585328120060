import { createContext, useContext } from "react";
import { useAuthUser } from "../../hooks";
import { User } from "../../types/user";

interface UserContextValues {
  user?: User;
  loading: boolean;
}

export const UserContext = createContext<UserContextValues>({
  loading: true,
});

export const UserContextProvider: React.FC = ({ children }) => {
  const { user, loading } = useAuthUser();

  return (
    <UserContext.Provider
      value={{
        user,
        loading,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useCurrentUser = (): UserContextValues => useContext(UserContext);
