import { Layout } from "antd";
import React from "react";
import styled from "styled-components";
import { useNavigate, Outlet } from "react-router-dom";

import { MobileNavigation, Navigation } from "../Navigation";
import { useCurrentUser } from "../User";

const { Content } = Layout;

export const MainLayout: React.FC = () => {
  const { user, loading } = useCurrentUser();
  const navigate = useNavigate();

  if (loading) {
    return null;
  }

  if (!user && !loading) {
    navigate("/login");
    return null;
  }
  
  return (
    <Layout>
      <Navigation />
      <MobileNavigation />

      <ContentLayout>
        <AppContent>
          <Outlet />
        </AppContent>
      </ContentLayout>
    </Layout>
  );
};

const ContentLayout = styled(Layout)`
  background-color: var(--white);
`;

ContentLayout.displayName = "ContentLayout";

const AppContent = styled(Content)`
  margin: 64px auto;
  width: 50vw;
`;

AppContent.displayName = "AppContent";
